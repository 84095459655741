import {Components} from 'formiojs-latest';

const FieldComponent = Components.components.field;

export const template = {
    form: `<div class="data-qr">
            <div>
                <img src="{{ component.qrImageSrc }}" />
            </div>
           </div>`
};

export default class QrCode extends FieldComponent {

    static schema() {
        return FieldComponent.schema({
            key: 'qrcode',
            type: 'qrcode'
        });
    }

    render(children) {
        return super.render(this.renderTemplate('qrcode', {}));
    }
}